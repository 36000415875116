/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect } from 'react';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { IconProfile, IconAlarm } from '@nzxt/react-icons';
import GTM from '@utils/gtm';
import { useAuth, useRegionFeatures, useTranslation as t } from '@utils/hooks';
import useUiStore, {
  getSetModalView,
  getOpenModal,
  getToggleAccountNav,
  getDisplayAccountNav,
  getCloseAccountNav,
  getDisplayOverlay,
  getSetModalContent,
} from '@stores/use-ui-store';
import useToastStore, { getDisplayToast } from '@stores/use-toast-store';
import useCustomer from '@framework/customer/use-customer';
import useLogout from '@framework/auth/use-logout';
import { MarketingBannerProps } from '@framework/api/types';
import getSupportUrl from '@framework/api/utils/get-support-url';
import ClickOutside from '@utils/click-outside';
import parseLocale from '@utils/parse-locale';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import slugify from '@utils/slugify';
import Button from '@components/Button';
import FooterLangControl from '@components/Layout/Footer/FooterLangControl';
import toastAlert from '@components/ToastAlert';
import { getRegion } from '@framework/api/utils/maxify';
import ToastMessage from '@components/ToastAlert/ToastMessage';
import IconAvatar from '@components/IconAvatar';
import NotificationBanner from '@components/Layout/NotificationBanner';
import ButtonLink from '@components/ButtonLink';
import * as styles from './styles';

type Props = {
  marketingBannerData?: MarketingBannerProps[];
};

const ProfileDropdown: FC<Props> = ({ marketingBannerData }) => {
  const setModalView = useUiStore(getSetModalView);
  const setModalContent = useUiStore(getSetModalContent);
  const openModal = useUiStore(getOpenModal);
  const displayAccountNav = useUiStore(getDisplayAccountNav);
  const toggleAccountNav = useUiStore(getToggleAccountNav);
  const closeAccountNav = useUiStore(getCloseAccountNav);
  const displayOverlay = useUiStore(getDisplayOverlay);

  const displayToast = useToastStore(getDisplayToast);

  const { isLoggedIn } = useAuth();
  const { data } = useCustomer();
  const router = useRouter();
  const { locale, defaultLocale, pathname } = router;
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang = parsedLocale[0];
  const region = getRegion(locale);
  const { signIn: showSignIn, support: showSupport } =
    useRegionFeatures(region);

  const hasBanner =
    Array.isArray(marketingBannerData) && marketingBannerData?.length > 0;

  const supportUrl = getSupportUrl(lang);
  const supportTitle = t('support_center_link');

  const logout = useLogout();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      toastAlert(
        'error',
        'left',
        <ToastMessage errorMessage={err.message} errorType="account" />,
        err
      );
    } finally {
      const res = await signOut({
        redirect: false,
        callbackUrl:
          pathname.includes('user') || pathname.includes('auth')
            ? process.env.NEXT_PUBLIC_BASE_URL
            : window.location.href,
      });
      router.push(res.url);
    }
  };

  const MESSAGE_USER_SIGN_OUT = t('user_sign_out');
  const MESSAGE_USER_SIGN_IN = 'My Account';
  const MESSAGE_NO_UPDATES = t('user_account_no_updates');
  const MESSAGE_ACCOUNT_UPDATES = t('user_account_updates');

  const PROFILE_LINKS = [
    { title: t('user_settings_title'), href: '/user/settings' },
    { title: t('user_saved_builds_heading'), href: '/user/saved-bld' },
    { title: t('user_order_history_heading'), href: '/user/order-history' },
    { title: t('user_support_heading'), href: '/user/support' },
  ];

  const handleLogin = (): void => {
    setModalView('LOGIN_VIEW');
    closeAccountNav();
    openModal();

    // track profile click via GA
    GTM.dataLayer({
      dataLayer: {
        event: 'profileClick',
      },
    });
  };

  const handleNoticeModal = (markdownContent, header): void => {
    setModalView('NOTICE_PAGE');
    setModalContent({ markdownCopy: header, markdownContent });
    openModal();
  };

  useEffect(() => {
    Router.events.on('routeChangeComplete', closeAccountNav);

    return () => {
      Router.events.off('routeChangeComplete', closeAccountNav);
    };
  }, [closeAccountNav]);

  return (
    <div className={styles.container}>
      <ClickOutside
        active={displayAccountNav}
        onClick={() => closeAccountNav()}
      >
        <div className={styles.dropdownWrapper}>
          <div>
            <button
              className={styles.button}
              id="user-menu"
              aria-haspopup="true"
              aria-expanded={displayAccountNav}
              onClick={() => toggleAccountNav()}
              type="button"
            >
              <span className={styles.srOnly}>Open user preferences</span>
              {data ? (
                <IconAvatar customer={data} />
              ) : (
                <IconProfile className={styles.profileSvg} />
              )}
            </button>
          </div>
          {displayAccountNav && displayOverlay && (
            <div
              className={styles.dropdownMenuWrapper(
                lang === 'de' || lang === 'nl'
              )}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
              data-test-id="user-menu-block"
            >
              <div
                className={styles.dropdownCtaContainer(
                  lang === 'de' || lang === 'nl'
                )}
              >
                <span
                  className={styles.flyoutCaret}
                  style={{ zIndex: -1, top: '-12px', right: '17px' }}
                />
                <div className={styles.langContainer}>
                  <FooterLangControl layout="vertical" isInverted />
                </div>
                {(showSupport || showSignIn) && (
                  <div
                    className={styles.accountLinksWrapper}
                    data-test-id="user-menu-items-block"
                  >
                    {showSupport && (
                      <>
                        <div className={styles.accountLinksDivider} />
                        <Link
                          href={supportUrl}
                          passHref
                          key={supportTitle}
                          className={styles.dropdownLink}
                          role="menuitem"
                          data-test-id={slugify(`user-menu-${supportTitle}`)}
                        >
                          {supportTitle}
                        </Link>
                      </>
                    )}
                    {showSignIn &&
                      (isLoggedIn ? (
                        <>
                          {PROFILE_LINKS.map(link => (
                            <Link
                              href={link.href}
                              passHref
                              key={link.title}
                              className={styles.accountLink}
                              role="menuitem"
                              data-test-id={slugify(`user-menu-${link.title}`)}
                            >
                              {link.title}
                            </Link>
                          ))}
                          <Button
                            data-test-id="user-menu-sign-out"
                            onClick={handleLogout}
                            buttonStyle="textButton"
                            className={styles.loginLinksBase}
                          >
                            {MESSAGE_USER_SIGN_OUT}
                          </Button>
                        </>
                      ) : (
                        <Button
                          data-test-id="user-menu-sign-in"
                          onClick={handleLogin}
                          buttonStyle="textButton"
                          className={styles.loginLinksBase}
                        >
                          {MESSAGE_USER_SIGN_IN}
                        </Button>
                      ))}
                    <a
                      href="https://flex.nzxt.com/portal/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.loginLinksBase}
                    >
                      My Flex Subscription
                    </a>
                  </div>
                )}
              </div>
              <div
                className={styles.dropdownInfoContainer(
                  lang === 'de' || lang === 'nl'
                )}
              >
                <p className={styles.updatesListHeading}>
                  <span className={styles.headingWrapper}>
                    <IconAlarm className={styles.alarmIcon} />
                    {MESSAGE_ACCOUNT_UPDATES}
                  </span>
                </p>
                <ul>
                  {hasBanner ? (
                    marketingBannerData.map(banner => {
                      const openNoticeModal =
                        banner?.internalLink?._modelApiKey === 'notice';

                      const internal =
                        !openNoticeModal && banner?.internalLink
                          ? getCmsLinkUrl({
                              contentType: banner.internalLink?._modelApiKey,
                              pageType: banner.internalLink?.pageType,
                              slug: banner.internalLink?.slug,
                              parentSlug:
                                banner.internalLink?.parentCategory?.slug,
                            })
                          : null;

                      return (
                        <li
                          className={styles.updatesListItem}
                          key={banner.bannerCopy}
                        >
                          {banner?.bannerCopy}{' '}
                          {internal && (
                            <Link
                              href={internal}
                              passHref
                              className={styles.updatesLink}
                            >
                              {banner?.buttonLinkLabel}
                            </Link>
                          )}
                          {openNoticeModal && banner?.buttonLinkLabel && (
                            <ButtonLink
                              className={styles.noticeLink}
                              onClick={() => {
                                handleNoticeModal(
                                  banner?.internalLink?.noticeModalContent,
                                  banner?.internalLink?.heading || null
                                );
                              }}
                              dark
                            >
                              {banner?.buttonLinkLabel}
                            </ButtonLink>
                          )}
                        </li>
                      );
                    })
                  ) : (
                    <p className={styles.baseText}>{MESSAGE_NO_UPDATES}</p>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </ClickOutside>
      {displayToast && <NotificationBanner flyout />}
    </div>
  );
};

export default ProfileDropdown;
